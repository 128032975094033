@import 'src/Styles/mixins/mixins';
@import "src/Styles/settings/variables";

.circle-status {
  width: 8px;
  height: 8px;
  display: inline-flex;
  background-color: #fff;
  border-radius: 8px;
  transition: all .2s ease-out;
  margin: 0 8px;

  &.succeeded {
    background-color: rgb(133, 204, 0);
  }

  &.canceled,
  &.failed {

    background-color: rgb(255, 46, 0);
  }

  &.pending {
    background-color: rgb(255, 191, 0);
  }

  &.charged_back {
    background-color: rgb(0, 59, 222);
  }

  &.refund {
    background-color: rgb(255, 46, 0);
  }

  &.refunded {
    background-color: rgb(255, 46, 0);
  }

  &.paid {
    background-color: rgb(133, 204, 0);
  }
}

.status {

  &.succeeded,
  &.paid {
    color: $success-color;
  }

  &.canceled,
  &.failed,
  &.refunded,
  &.refund,
  &.charged_back {
    color: $error-color;
  }

  &.pending {
    color: $warning-color;
  }
}

.nohover {
  .rt-tr-group {
    .rt-tr {
      cursor: default !important;
    }

    &:hover {
      background-color: unset !important
    }
  }
}

.old-common-box {
  .no-padding {
    margin: -15px 0;
    padding: 0;
  }

  .min-height-sm {
    min-height: 100px;
  }

  .min-height-md {
    min-height: 200px;
  }

  .min-height-lg {
    min-height: 300px;
  }

  .loader-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.7);

    -webkit-animation: fade-in 0.5s ease-out both;
    animation: fade-in 0.5s ease-out both;

    .loader-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .filter-row {
    margin-top: -15px;
    border-bottom: 1px solid $card-filter-border-color;
    margin-bottom: 15px;
  }

  .ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    @include boxShadow(#000000, 0.2);
    border: 0 none;
    box-shadow: none;

    * {
      box-sizing: border-box;
    }

    &.row-link {
      .rt-tr-group {
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }

    .rt-table {
      flex: auto 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      border-collapse: collapse;
      overflow: auto;
    }


    .rt-thead {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      color: #555555;
      font-weight: bold;
      background: transparent;

      &.-headerGroups {
        background: rgba(0, 0, 0, 0.03);
      }

      &.-filters {

        input,
        select {
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: #fff;
          padding: 5px 7px;
          font-size: inherit;
          border-radius: 3px;
          font-weight: normal;
          outline: none;
        }

        .rt-th {
          border-right: 1px solid rgba(0, 0, 0, 0.02);
        }
      }

      .rt-tr {
        background: transparent;
        padding: 0px 30px;
        font-size: $small;
        font-weight: bold;

        &:hover {
          .rt-resizer {
            &::after {
              position: absolute;
              left: 50%;
              width: 1px;
              height: 100%;
              content: ".";
              color: transparent;
              transform: translate(-50%, 0);
              border-left: 1px dotted rgba(0, 0, 0, 0.3);
            }
          }
        }

      }

      .rt-th,
      .rt-td {
        padding: 15px 5px;
        line-height: normal;
        position: relative;
        transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        box-shadow: inset 0 0 0 0 transparent;
      }

      .rt-th.-sort-asc,
      .rt-td.-sort-asc {
        box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
      }

      .rt-th.-sort-desc,
      .rt-td.-sort-desc {
        box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
      }

      .rt-th.-cursor-pointer,
      .rt-td.-cursor-pointer {
        cursor: pointer;
      }

      .rt-th:last-child,
      .rt-td:last-child {
        border-right: 0;
      }

      .rt-th:focus {
        outline: none;
      }

      .rt-resizable-header {
        overflow: visible;

        &:last-child {
          overflow: hidden;
        }
      }

      .rt-resizable-header-content {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .rt-header-pivot {
        border-right-color: #f7f7f7;

        &:after,
        &:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-left-color: #fff;
          border-width: 8px;
          margin-top: -8px;
        }

        &:before {
          border-color: rgba(102, 102, 102, 0);
          border-left-color: #f7f7f7;
          border-width: 10px;
          margin-top: -10px;
        }
      }
    }

    .rt-tbody {
      flex: 99999 1 auto;
      display: flex;
      flex-direction: column;
      overflow: auto;
      background-color: #FFFFFF;

      .rt-tr-group {
        border-bottom: solid 1px rgba(0, 0, 0, 0.05);

        &:last-child {
          border-bottom: 0;
        }

        .rt-tr {
          padding: 0 30px;
        }
      }

      .rt-td {
        padding: $middlePadding $spaceMargin;
        font-size: $small;

        &:last-child {
          border-right: 0;
        }
      }

      .rt-expandable {
        cursor: pointer;
        text-overflow: clip;
      }
    }

    .rt-tr-group {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    .rt-tr {
      flex: 1 0 auto;
      display: inline-flex;
    }

    .rt-th,
    .rt-td {
      flex: 1 0 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 7px 5px;
      overflow: hidden;
      transition: 0.3s ease;
      transition-property: width, min-width, padding, opacity;
    }

    .rt-th.-hidden,
    .rt-td.-hidden {
      width: 0 !important;
      min-width: 0 !important;
      border: 0 !important;
      opacity: 0 !important;
    }

    .rt-expander {
      display: inline-block;
      position: relative;
      color: transparent;
      margin: 0 10px;

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        border-left: 5.04px solid transparent;
        border-right: 5.04px solid transparent;
        border-top: 7px solid rgba(0, 0, 0, 0.8);
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        cursor: pointer;
      }

      &.-open:after {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }

    .rt-resizer {
      display: inline-block;
      position: absolute;
      width: 36px;
      top: 0;
      bottom: 0;
      right: -18px;
      cursor: col-resize;
      z-index: 10;
    }

    .rt-tfoot {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0px 15px 0px rgba(0, 0, 0, 0.15);

      .rt-td {
        border-right: 1px solid rgba(0, 0, 0, 0.05);

        &:last-child {
          border-right: 0;
        }
      }
    }

    .rt-tr.-odd {
      background: rgba(0, 0, 0, 0.03);
    }

    &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
      background: rgba(0, 0, 0, 0.05);
    }

    .-pagination {
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      padding: 3px;
      box-shadow: 0 0px 15px 0px rgba(0, 0, 0, 0.1);
      border-top: 2px solid rgba(0, 0, 0, 0.1);

      input,
      select {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none;
      }

      .-btn {
        appearance: none;
        display: block;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 3px;
        padding: 6px;
        font-size: 1em;
        color: rgba(0, 0, 0, 0.6);
        background: rgba(0, 0, 0, 0.1);
        transition: all 0.1s ease;
        cursor: pointer;
        outline: none;

        &[disabled] {
          opacity: 0.5;
          cursor: default;
        }

        &:not([disabled]):hover {
          background: rgba(0, 0, 0, 0.3);
          color: #fff;
        }
      }

      .-previous,
      .-next {
        flex: 1;
        text-align: center;
      }

      .-center {
        flex: 1.5;
        text-align: center;
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
      }

      .-pageInfo {
        display: inline-block;
        margin: 3px 10px;
        white-space: nowrap;
      }

      .-pageJump {
        display: inline-block;

        input {
          width: 70px;
          text-align: center;
        }
      }

      .-pageSizeOptions {
        margin: 3px 10px;
      }
    }

    .rt-noData {
      text-align: center;
      transition: all 0.3s ease;
      pointer-events: none;
      color: rgba(0, 0, 0, 0.5);
    }

    .-loading {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.8);
      transition: all 0.3s ease;
      z-index: -1;
      opacity: 0;
      pointer-events: none;

      >div {
        position: absolute;
        display: block;
        text-align: center;
        width: 100%;
        top: 50%;
        left: 0;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.6);
        transform: translateY(-52%);
        transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      }

      &.-active {
        opacity: 1;
        z-index: 2;
        pointer-events: all;

        >div {
          transform: translateY(50%);
        }
      }
    }

    .rt-resizing {

      .rt-th,
      .rt-td {
        transition: none !important;
        cursor: col-resize;
        user-select: none;
      }
    }
  }

}

.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  @include boxShadow(#000000, 0.2);
  border: 0 none;
  box-shadow: none;

  &.row-link {
    .rt-tr-group {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  .rt-th>div,
  .rt-td {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .rt-thead {
    color: $dark-color;
    font-weight: bold;
    border-bottom: solid 2px $dark-color;

    &.-filters {

      input,
      select {
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        padding: 5px 7px;
        font-size: inherit;
        border-radius: 3px;
        font-weight: normal;
        outline: none;
      }

      .rt-th {
        border-right: 1px solid rgba(0, 0, 0, 0.02);
      }
    }

    .rt-tr {
      background: transparent;
      font-size: $small;
      font-weight: bold;
    }

    .rt-th,
    .rt-td {
      padding: $middlePadding $middlePadding $middlePadding 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .rt-th.-cursor-pointer,
    .rt-td.-cursor-pointer {
      cursor: pointer;
    }

    .rt-th:last-child,
    .rt-td:last-child {
      border-right: 0;
    }

    .rt-th:focus {
      outline: none;
    }

    .rt-header-pivot {
      border-right-color: #f7f7f7;

      &:after,
      &:before {
        left: 100%;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #fff;
        border-width: 8px;
        margin-top: -8px;
      }

      &:before {
        border-color: rgba(102, 102, 102, 0);
        border-left-color: #f7f7f7;
        border-width: 10px;
        margin-top: -10px;
      }
    }
  }

  .rt-tbody {
    background-color: #FFFFFF;

    .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);

      &:last-child {
        border-bottom: 0;
      }

      .rt-tr {
        cursor: pointer;
        align-items: center;
      }
    }

    .rt-td {
      padding: $middlePadding $middlePadding $middlePadding 0;
      font-size: $small;

      &:last-child {
        border-right: 0;
      }
    }

    .rt-expandable {
      cursor: pointer;
      text-overflow: clip;
    }
  }

  .rt-tr-group {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .rt-tr {
    flex: 1 0 auto;
    display: flex;
  }

  .rt-th.-hidden,
  .rt-td.-hidden {
    width: 0 !important;
    min-width: 0 !important;
    border: 0 !important;
    opacity: 0 !important;
  }

  .rt-expander {
    display: inline-block;
    position: relative;
    color: transparent;
    margin: 0 10px;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      border-left: 5.04px solid transparent;
      border-right: 5.04px solid transparent;
      border-top: 7px solid rgba(0, 0, 0, 0.8);
      transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      cursor: pointer;
    }

    &.-open:after {
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }

  .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10;
  }

  .rt-tfoot {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0px 15px 0px rgba(0, 0, 0, 0.15);

    .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.05);

      &:last-child {
        border-right: 0;
      }
    }
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .-pagination {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 3px;
    box-shadow: 0 0px 15px 0px rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1);

    input,
    select {
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: normal;
      outline: none;
    }

    .-btn {
      appearance: none;
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 3px;
      padding: 6px;
      font-size: 1em;
      color: rgba(0, 0, 0, 0.6);
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.1s ease;
      cursor: pointer;
      outline: none;

      &[disabled] {
        opacity: 0.5;
        cursor: default;
      }

      &:not([disabled]):hover {
        background: rgba(0, 0, 0, 0.3);
        color: #fff;
      }
    }

    .-previous,
    .-next {
      flex: 1;
      text-align: center;
    }

    .-center {
      flex: 1.5;
      text-align: center;
      margin-bottom: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
    }

    .-pageInfo {
      display: inline-block;
      margin: 3px 10px;
      white-space: nowrap;
    }

    .-pageJump {
      display: inline-block;

      input {
        width: 70px;
        text-align: center;
      }
    }

    .-pageSizeOptions {
      margin: 3px 10px;
    }
  }

  .rt-noData {
    text-align: center;
    transition: all 0.3s ease;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.5);
  }

  .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;

    >div {
      position: absolute;
      display: block;
      text-align: center;
      width: 100%;
      top: 50%;
      left: 0;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
      transform: translateY(-52%);
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &.-active {
      opacity: 1;
      z-index: 2;
      pointer-events: all;

      >div {
        transform: translateY(50%);
      }
    }
  }

  .rt-resizing {

    .rt-th,
    .rt-td {
      transition: none !important;
      cursor: col-resize;
      user-select: none;
    }
  }
}

.no-data {
  text-align: center;
  margin: 0 $margin;
  height: 100%;
}
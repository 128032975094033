@import "src/Styles/settings/variables.scss";
@import "src/Styles/mixins/mixins.scss";

.timeline {
  list-style: none;
  position: relative;
  padding: 0;

  @include mq(tablet) {
    padding: $padding 0 $padding;
  }
}

  .timeline > li {
    display: flex;
    margin-bottom: $smallMargin;
  }

  .timeline > li > .timeline-panel {
    width: 100%;
    padding: 0 $smallPadding;
    position: relative;
  }

  .timeline > li {
    .timeline-badge {
      position: relative;

      .statusIcon {
        span {
          margin-left: 0;
        }
      }
    }

    &:not(:last-child){
      .timeline-badge {
        &:before {
          top: 21%;
          position: absolute;
          content: "";
          width: 1px;
          height: 100%;
          background-color: #cecece;
          left: 50%;
        }
      }
    }
  }

  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
  }

  .timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  .timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }

  .timeline-badge.primary {
    background-color: #2e6da4 !important;
  }
  li.success {
    .timeline-badge {
      background-color: #3f903f !important;
    }
    .timeline-title {
      color: #3f903f !important;
    }
  }
  li.danger {
    .timeline-badge {
      background-color: #d9534f !important;
    }
    .timeline-title {
      color: #d9534f !important;
    }
  }
  .timeline-badge.warning {
    background-color: #f0ad4e !important;
  }

  .timeline-badge.danger {
    background-color: #d9534f !important;
  }

  .timeline-badge.info {
    background-color: #5bc0de !important;
  }


  .timeline-body > p,
  .timeline-body > ul {
    margin-bottom: 0;
  }

  .timeline-body > p + p {
    margin-top: 5px;
  }

@import "src/Styles/settings/variables.scss";

.containers-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $margin;

  h1 {
    margin: 0;
  }
}
